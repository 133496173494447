import React, { useEffect } from 'react';

const Codex = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <div className="codex-wrapper">
            <h1 className="codex-title">Этический кодекс ассоциации Global Association of IT Experts (GAITE)</h1>

            <div className="codex-section">
                <h2 className="codex-subtitle">1. Общие положения</h2>
                <p className="codex-text">
                    1.1. Этический кодекс Global Association of IT Experts (GAITE) (далее - Кодекс) устанавливает нормы и правила поведения для членов ассоциации, направленные на поддержание высоких стандартов профессиональной этики и содействие развитию IT-индустрии.
                </p>
                <p className="codex-text">
                    1.2. Кодекс обязателен для всех членов GAITE и распространяется на их профессиональную деятельность, взаимодействие с коллегами, клиентами, партнерами и общественностью.
                </p>
            </div>

            <div className="codex-section">
                <h2 className="codex-subtitle">2. Профессиональная компетентность и ответственность</h2>
                <p className="codex-text">
                    2.1. Члены GAITE обязаны поддерживать высокий уровень профессиональной компетентности, постоянно совершенствовать свои знания и навыки, следить за новыми тенденциями и инновациями в области IT.
                </p>
                <p className="codex-text">
                    2.2. Члены GAITE должны выполнять свои профессиональные обязанности с наивысшим уровнем ответственности, качественно и в срок, проявляя добросовестность и внимание к деталям.
                </p>
                <p className="codex-text">
                    2.3. Члены GAITE обязаны соблюдать все применимые законы и нормативные акты, а также внутренние регламенты ассоциации.
                </p>
            </div>

            <div className="codex-section">
                <h2 className="codex-subtitle">3. Честность и прозрачность</h2>
                <p className="codex-text">
                    3.1. Члены GAITE должны быть честными и открытыми в своих профессиональных взаимоотношениях, предоставлять достоверную информацию и избегать умышленного введения в заблуждение.
                </p>
                <p className="codex-text">
                    3.2. Члены GAITE обязаны уважать интеллектуальную собственность других, избегать плагиата и неправомерного использования чужих идей и разработок.
                </p>
                <p className="codex-text">
                    3.3. Члены GAITE должны избегать конфликта интересов и сообщать о любых ситуациях, которые могут повлиять на их объективность и независимость.
                </p>
            </div>

            <div className="codex-section">
                <h2 className="codex-subtitle">4. Уважение и сотрудничество</h2>
                <p className="codex-text">
                    4.1. Члены GAITE должны уважать своих коллег, клиентов, партнеров и других участников профессионального сообщества, проявлять толерантность и учитывать разнообразие мнений и культур.
                </p>
                <p className="codex-text">
                    4.2. Члены GAITE обязаны способствовать созданию атмосферы сотрудничества, обмену знаниями и опытом, поддерживать и поощрять развитие профессиональных связей.
                </p>
                <p className="codex-text">
                    4.3. Члены GAITE должны избегать недобросовестной конкуренции и способствовать честной и справедливой конкуренции на рынке IT-услуг.
                </p>
            </div>

            <div className="codex-section">
                <h2 className="codex-subtitle">5. Защита данных и конфиденциальность</h2>
                <p className="codex-text">
                    5.1. Члены GAITE обязаны уважать конфиденциальность информации, полученной в ходе профессиональной деятельности, и принимать все необходимые меры для защиты персональных данных и коммерческой тайны.
                </p>
                <p className="codex-text">
                    5.2. Члены GAITE должны использовать информацию только в законных и этически обоснованных целях, избегая ее неправомерного раскрытия или использования.
                </p>
            </div>

            <div className="codex-section">
                <h2 className="codex-subtitle">6. Социальная ответственность и устойчивое развитие</h2>
                <p className="codex-text">
                    6.1. Члены GAITE обязаны способствовать развитию IT-индустрии, ориентируясь на принципы устойчивого развития и социальной ответственности.
                </p>
                <p className="codex-text">
                    6.2. Члены GAITE должны поддерживать инициативы, направленные на улучшение качества жизни общества, включая образовательные программы, экологические проекты и социальные инициативы.
                </p>
                <p className="codex-text">
                    6.3. Члены GAITE обязаны пропагандировать принципы этичного и ответственного использования технологий, способствующих прогрессу и благополучию общества.
                </p>
            </div>

            <div className="codex-section">
                <h2 className="codex-subtitle">7. Заключительные положения</h2>
                <p className="codex-text">
                    7.1. Настоящий Кодекс вступает в силу с момента его утверждения Советом GAITE и обязателен для всех членов ассоциации.
                </p>
                <p className="codex-text">
                    7.2. Нарушение положений Кодекса может повлечь за собой дисциплинарные меры, включая предупреждение, временное приостановление членства или исключение из GAITE.
                </p>
                <p className="codex-text">
                    7.3. Контроль за соблюдением настоящего Кодекса возлагается на этическую комиссию GAITE, которая имеет право рассматривать жалобы и принимать решения по вопросам нарушения Кодекса.
                </p>
            </div>
        </div>
    );
};

export default Codex;
